<template>
  <div>
    <section class="card-scroll">
      <div class="card--content shadow p-3 mb-5 bg-white rounded" v-for="(product, index) in products" :key="index">
        <div class="mt-1">
          <span class="text-primary font-weight-bold title-text">Shipper</span>
          <br />
          {{product.shipper && product.shipper.name != null ? product.shipper.name : 'N/A'}}
        </div>
        <div class="mt-1">
          <span class="text-primary font-weight-bold title-text">Supplier</span>
          <br />
          {{product.supplier && product.supplier.name != null ? product.supplier.name : 'N/A'}}
        </div>
        <div class="mt-1">
          <span class="text-primary font-weight-bold title-text">Account Of</span>
          <br />
          {{product.accountOf && product.accountOf.name != null ? product.accountOf.name : 'N/A'}}
        </div>
        <div class="mt-1">
          <span class="text-primary font-weight-bold title-text">Commodity</span>
          <br />
          {{product.product.name }}
        </div>
        <div class="mt-1">
          <span class="text-primary font-weight-bold title-text">Quantity</span>
          <br />
          {{product.amount}} {{product.amountUnitOfMeasure}}
        </div>
      </div>
    </section>
    <div class="row">
      <div class="col text-center" v-if="products.length > 1">
        <i class="fa fa-arrows-h text-primary" style="font-size:2em"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["products"]
};
</script>

<style scoped>
.card-scroll {
  background-color: #fff;
  min-width: 100%;
  min-height: 150px;
  overflow-x: auto;
  display: flex;
}

.card--content {
  border: 1px solid rgba(12, 149, 211, 0.25);
  border-radius: 5px;
  min-width: 200px;
  margin: 5px;
  padding: 10px;
}

.title-text {
  font-size: 16px;
}
</style>
